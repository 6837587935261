import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
   <div>
     <div class="container-xxl position-relative p-0">
    
        <nav style={{backgroundColor: 'blueviolet'}} class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <Link  to="/" class="navbar-brand p-0"  className="navbar-brand"><h1 class="m-0">META BIZ</h1>
        <h5 class="m-0">Solutions & Services</h5></Link>
           
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="fa fa-bars"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="navbar-nav mx-auto py-0">
                    <Link to="/" class="nav-item nav-link active">Home</Link>
                    <Link to="/aboutus"  class="nav-item nav-link">About</Link>
                    <Link to="/supplies"  class="nav-item nav-link">Supplies</Link>
                    <Link to="/services" class="nav-item nav-link">Services</Link>
                    <Link to="/projects" class="nav-item nav-link">Project</Link>
                    <Link to="/clients" class="nav-item nav-link">Clients</Link>
                    <div class="nav-item dropdown">
                        <Link to="/" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</Link>
                        <div class="dropdown-menu m-0">
                            <Link to="/team" class="dropdown-item">Our Team</Link>
                           
                        </div>
                    </div>
                    <Link to="/footer" class="nav-item nav-link">Contact</Link>
                </div>
            </div>
        </nav>

      
    </div>
    </div>
  )
}

export default Header