import React from 'react'
 import Header1 from './Header1'
import Aboutus from './Aboutus'
import Services from './Services'
import Supplies from "./Supplies"
import Projects from "./Projects"
import Team from './Team'
import Clients from './Clients'

const Home = () => {
  return (
    <div>
    <body>
    <div class="container-xxl bg-white p-0">
    <Header1/>
    <Aboutus/>
    <Services/>
    <Supplies/>
    <Projects/>
    <Clients/>
    <Team/>
    <a href="#" class="btn btn-lg btn-secondary btn-lg-square back-to-top"><i class="bi bi-arrow-up"></i></a>
    </div>
        </body>
    </div>
  )
}

export default Home